<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{ $t("Adicionar novo usuário") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="registerNewUserForm" #default="{ invalid }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="register">
          <!-- Full Name -->
          <label label-for="register-fullname">{{ $t("Nome completo") }}</label>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              mode="eager"
              name="Fullname"
              vid="fullName"
              rules="min:6|max:100"
            >
              <b-form-input
                id="register-fullname"
                v-model="fullName"
                name="register-fullname"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>          

          <!-- Email -->
          <label label-for="register-email">{{ $t("E-mail") }}</label>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              mode="eager"
              name="Email"
              vid="email"
              rules="min:6|max:80|email"
            >
              <b-form-input
                id="register-email"
                v-model="userEmail"
                name="register-email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || loading"
            >
              <div v-if="!loading">
                {{ $t("Adicionar") }}
              </div>
              <div v-else>
                <b-spinner small type="grow"></b-spinner>
                {{ $t("Carregando...") }}
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancelar") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, email, min, max } from "@/libs/validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    paramsList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Form input
      fullName: "",
      username: "",
      userEmail: "",

      // Form Validate
      required,
      email,
      min,
      max,

      loading: false,
    };
  },
  computed: {
    ...mapGetters(["returnLocale"]),
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      localize(oldVal);
    },
  },
  methods: {
    ...mapActions(["addNewUser", "usersList"]),
    register() {
      this.$refs.registerNewUserForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            fullName: this.fullName,
            username: this.username,
            email: this.userEmail,
          };
          this.addNewUser(data)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CheckCircleIcon",
                  variant: "success",
                  title: this.$i18n.t("Novo usuário cadastrado!"),
                },
              });
              this.fullName = "";
              this.username = "";
              this.userEmail = "";
              this.loading = false;
              this.usersList(this.paramsList);
            })
            .catch((error) => {
              this.$refs.registerNewUserForm.setErrors(error);
              this.loading = false;
            });
        }
      });
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store.dispatch("app-user/addUser", userData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
